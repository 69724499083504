.clearfix
    zoom: 1

    &:before, &:after
        content: "."
        visibility: hidden
        display: block
        height: 0
        overflow: hidden

    &:after
        clear: both
