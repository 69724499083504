html, body
    margin:  0
    padding: 0

div, span, img, a, fieldset, label, legend, form, pre,
p, h1, h2, h3, h4, h5, h6,
table, tbody, tfoot, thead, tr, th, td, dl, dt, dd, ul, ol,
blockquote
    margin:  0
    padding: 0
    border:  0
    outline: 0
    vertical-align: baseline
    background: transparent
    font-size: 100%
    font-weight: inherit

th, caption
    text-align: left

input
    margin:  0
    padding: 0
    font-family: inherit
    font-weight: inherit
    color: inherit

button
    font-family: inherit
    font-weight: inherit

\:focus
    outline: 0

table
    border-collapse: collapse
    border-spacing: 0
