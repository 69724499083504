$fonts-root: 'fonts/'


@font-face
    font-family: PTRouble
    src: url($fonts-root + 'PTRouble-Normal.eot')
    src: local('_unexisting_'), url($fonts-root + 'PTRouble-Normal.woff') format('woff'), url($fonts-root + 'PTRouble-Normal.ttf') format('truetype'), url($fonts-root + 'PTRouble-Normal.svg#PTRoubleSans') format('svg')
    font-weight: normal
    font-style: normal

@font-face
    font-family: PTRouble
    src: url($fonts-root + 'PTRouble-Bold.eot')
    src: local('_unexisting_'), url($fonts-root + 'PTRouble-Bold.woff') format('woff'), url($fonts-root + 'PTRouble-Bold.ttf') format('truetype'), url($fonts-root + 'PTRouble-Bold.svg#PTRoubleSans') format('svg')
    font-weight: bold
    font-style: normal

@font-face
    font-family: PTRouble
    src: url($fonts-root + 'PTRouble-Italic.eot')
    src: local('_unexisting_'), url($fonts-root + 'PTRouble-Italic.woff') format('woff'), url($fonts-root + 'PTRouble-Italic.ttf') format('truetype'), url($fonts-root + 'PTRouble-Italic.svg#PTRoubleSans') format('svg')
    font-weight: normal
    font-style: italic

@font-face
    font-family: PTRouble
    src: url($fonts-root + 'PTRouble-Bold-Italic.eot')
    src: local('_unexisting_'), url($fonts-root + 'PTRouble-Bold-Italic.woff') format('woff'), url($fonts-root + 'PTRouble-Bold-Italic.ttf') format('truetype'), url($fonts-root + 'PTRouble-Bold-Italic.svg#PTRoubleSans') format('svg')
    font-weight: bold
    font-style: italic



.rouble
    font-family: PTRouble
    text-transform: uppercase
