$fonts-root: 'fonts/'


@font-face
    font-family: SegoeUI
    src: url($fonts-root + 'SegoeUI-Regular-subset.eot')
    src: local('Segoe UI'), url($fonts-root + 'SegoeUI-Regular-subset.woff') format('woff'), url($fonts-root + 'SegoeUI-Regular-subset.ttf')  format('truetype'), url($fonts-root + 'SegoeUI-Regular-subset.svg#SegoeUI') format('svg')
    font-weight: normal
    font-style: normal

@font-face
    font-family: SegoeUI
    src: url($fonts-root + 'SegoeUI-Light-subset.eot')
    src: local('Segoe UI Light'), url($fonts-root + 'SegoeUI-Light-subset.woff') format('woff'), url($fonts-root + 'SegoeUI-Light-subset.ttf')  format('truetype'), url($fonts-root + 'SegoeUI-Light-subset.svg#SegoeUI-Light') format('svg')
    font-weight: 100
    font-style: normal

@font-face
    font-family: SegoeUI
    src: url($fonts-root + 'SegoeUI-Semibold-subset.eot')
    src: local('Segoe UI Semibold'), url($fonts-root + 'SegoeUI-Semibold-subset.woff') format('woff'), url($fonts-root + 'SegoeUI-Semibold-subset.ttf')  format('truetype'), url($fonts-root + 'SegoeUI-Semibold-subset.svg#SegoeUI-Semibold') format('svg')
    font-weight: bold
    font-style: normal

@font-face
    font-family: SegoeUI
    src: url($fonts-root + 'SegoeUI-Italic-subset.eot')
    src: local('Segoe UI Italic'), local('Segoe UI'), url($fonts-root + 'SegoeUI-Italic-subset.woff') format('woff'), url($fonts-root + 'SegoeUI-Italic-subset.ttf')  format('truetype'), url($fonts-root + 'SegoeUI-Italic-subset.svg#SegoeUI-Italic') format('svg')
    font-weight: normal
    font-style: italic
